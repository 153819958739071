import React from 'react';
import { graphql } from 'gatsby';

import PageHero from '../components/page-hero';
import Column from '../components/column';
import Cta from '../components/cta';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';
import { Iframe, IframeContainer } from '../components/iframe';
import Container from '../components/container';

const Music = ({ data: { heroImg } }) => (
  <Layout>
    <PageHero img={heroImg} videoId="436159343">
      <Heading as="h1">Music</Heading>
      <Cta to="#show-more">show more</Cta>
    </PageHero>
    <Section id="show-more">
      <Container>
        <Row>
          <Column offset="2">
            <Heading as="h2">Music at Polish Boogie Festival</Heading>
          </Column>
        </Row>
        <Row>
          <Column offset={2}>
            <p>
              You will not read about those whom we host on our stage in gossip magazines. They do not dominate the top charts. They often travel all over the world to play the very one concert at our festival. One thing is for sure, they light the stage red with their energy, passion and emotions. This is the most important thing that make us proud.
            </p>
          </Column>
        </Row>
        <Row justify="center">
          <IframeContainer>
            <Iframe title="vimeo-player" src="https://player.vimeo.com/video/685842249" frameBorder="0" allowFullScreen />
          </IframeContainer>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Music;

export const MusicQuery = graphql`
  query MusicQuery {
    heroImg: file(relativePath: { eq: "muzyka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
